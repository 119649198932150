<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <!-- <div class="col-12 md:col-6">
    <app-user-selector
      [label]="'Members'"
      [placeholder]="'Search Users'"
      [control]="formGroup?.controls?.people"
      [multi]="true"
      [viewMode]="fieldViewMode"

      >
    </app-user-selector>
  </div> -->

    <div *ngxPermissionsOnly="mapperService?.mappedFields['organization']?.permissions" class="col-12 md:col-6">
      <!-- <app-entity-category-tree-selector
    [label]="'Organizations'"
    [placeholder]="'Search Organizations'"
    [control]="formGroup?.controls?.organization"
    [multi]="false"
    [viewMode]="viewModeService.viewMode"
    [selectedEntities]="[data?.organization]"
    [dropDown]="true"
    [categoryList]="orgList"
    [returnObject]="false"
    [excludeId]="itemId"
    >
  </app-entity-category-tree-selector> -->
      <app-target-code-selector
        [label]="moduleFieldString + '.organization.label' | translate: 'Organization'"
        [placeholder]="moduleFieldString + '.organization.placeholder' | translate: 'Search Organization'"
        [excludeCodes]="itemId ? [itemId] : []"
        [control]="formGroup?.controls?.organization"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['ORGANIZATION']"
      >
      </app-target-code-selector>
    </div>
    @if (fieldViewMode != 'create') {
      <div class="col-12 md:col-6">
        <app-user-and-group-selector
          [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
          [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Search...'"
          [principleMode]="true"
          [control]="formGroup?.controls?.owner"
          [optionValue]="undefined"
          [multi]="false"
          [viewMode]="fieldViewMode == 'edit' && editOwner ? 'edit' : 'view'"
        >
        </app-user-and-group-selector>
      </div>
    }

    <div *ngxPermissionsOnly="mapperService?.mappedFields['type']?.permissions" class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.entityType.label' | translate: 'Entity Type'"
        [placeholder]="moduleFieldString + '.entityType.placeholder' | translate: 'Search...'"
        [control]="formGroup?.controls?.type"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [dataKey]="'code'"
        [extraFilters]="this.categoryList ? [{ property: 'category', operation: 'IN', value: this.categoryList }] : []"
        [customProjectionFields]="[
          'code',
          'recordStatus',
          'label',
          'name',
          'color',
          'icon',
          'fields',
          'options',
          'key',
          'type',
        ]"
        (onChanges)="onEntityTypeChange($event)"
        [targetTypes]="['ENTITY_TYPE']"
      >
      </app-target-code-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.parent.label' | translate: 'Parent'"
        [placeholder]="moduleFieldString + '.parent.placeholder' | translate: 'Search...'"
        [excludeCodes]="itemId ? [itemId] : []"
        [control]="formGroup?.controls?.parent"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['RESPONSIBILITY']"
        [extraFilters]="
          formGroup?.controls?.organization?.value
            ? [{ property: 'organization', operation: 'EQUAL', value: formGroup?.controls?.organization?.value }]
            : []
        "
      >
      </app-target-code-selector>
      <!-- <app-entity-category-tree-selector
  [label]="'Parent'"
  [placeholder]="'Search Entities'"
  [control]="formGroup?.controls?.parent"
  [multi]="false"
  [viewMode]="fieldViewMode"
  [selectedEntities]="[data?.parent]"
  [dropDown]="true"
  [categoryList]="categoryList"
  [returnObject]="false"
  [excludeId]="itemId"
  [extraFilters]="formGroup?.controls?.organization?.value ? [{'property': 'organization', 'operation': 'EQUAL', 'value': formGroup?.controls?.organization?.value}] : []"
  [tempMode]="true"
  >
</app-entity-category-tree-selector> -->
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>

    <div class="col-12">
      <app-dynamic-field-list-input
        [label]="moduleFieldString + '.dynamics.label' | translate: 'Dynamic Fields'"
        [placeholder]="moduleFieldString + '.dynamics.placeholder' | translate: 'Dynamic Fields'"
        [control]="formGroup?.controls?.dynamics"
        [viewMode]="fieldViewMode"
        [config]="{
          dynamicFields:
            formGroup?.controls?.type?.value?.code != data?.type
              ? formGroup?.controls?.type?.value?.fields
              : data?.entityType?.fields,
          dynamicFieldValues: formGroup?.controls?.type?.value?.code != data?.type ? null : data?.dynamics,
        }"
      >
      </app-dynamic-field-list-input>
    </div>
  </div>
  <!-- <ng-content></ng-content> -->
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
<!-- <ng-container *ngIf="this?.data?.name" >

<ng-template *ngxPermissionsOnly="updateGroupPermission; else elseBlock; then thenBlock">
</ng-template>
<ng-template #elseBlock>
  <p-divider class="w-full mt-4"
    align="left">
    <b>Membership</b>
  </p-divider>
  <div class="col-12 ">
    <app-basic-input label="Group Name" [placeholder]="'Enter Name'" [control]="formGroup?.controls?.groupName"
    [viewMode]="fieldViewMode" [readOnly]="true"></app-basic-input>
    <div class="col-12">
      <app-user-picklist-selector
        (resultlements)="setUsers($event)"
        (initialSetElements)="setInitialDataElements($event)"
        [targetElements]="selectedUsers"
        [viewMode]="'view'"
        >
      </app-user-picklist-selector>
    </div>
  </div>
</ng-template>
<ng-template #thenBlock>
  <p-divider class="w-full mt-4"
    align="left">
    <b>Membership</b>
  </p-divider>
  <div class="col-12 ">
    <app-basic-input label="Group Name" [placeholder]="'Enter Name'" [control]="formGroup?.controls?.groupName"
    [viewMode]="fieldViewMode" [readOnly]="true"></app-basic-input>
  </div>
  <div class="col-12">
    <app-user-picklist-selector
      (resultlements)="setUsers($event)"
      (initialSetElements)="setInitialDataElements($event)"
      [targetElements]="selectedUsers"
      [viewMode]="fieldViewMode"
      >
    </app-user-picklist-selector>
  </div>
  <div class="flex flex-row-reverse gap-2 mt-2">
    <app-button *ngIf="fieldViewMode == 'edit'" [action]="submitSaveGroupButtonAction"></app-button>
  </div>
</ng-template>
</ng-container> -->
