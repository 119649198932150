import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AppPermissions,
  BaseForm,
  EntityTypeFieldDto,
  IAction,
  MessageKeys,
  ModuleKeywords,
  ResponsibilityFieldDto,
  TargetTypeEnum,
} from '@shared/classes';
import { ResponsibilitiesMappingService } from '@shared/services/mappings/entity/responsibilities-mapping.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { GroupDataService } from 'app/modules/users/services/data/group-data.service';
import { differenceBy } from 'lodash-es';
import { Subscription, forkJoin, of } from 'rxjs';
import { EntityTypesDataService } from '../../services/data/entity-types-data.service';

@Component({
  selector: 'app-responsibilities-item-form',
  templateUrl: './responsibilities-item-form.component.html',
  styleUrls: ['./responsibilities-item-form.component.scss'],
})
export class ResponsibilitiesItemFormComponent extends BaseForm<ResponsibilityFieldDto> implements OnInit {
  @Input() itemId: string = null;
  @Input() editOwner: boolean = false;
  organizationsType: TargetTypeEnum = TargetTypeEnum.Organization;
  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];

  orgList = [EntityTypeFieldDto.CategoryEnum.Organization];
  orgSubscriber: Subscription = null;
  selectedUsers;
  currentUsers: any[];
  initialUsers: any[];
  submitSaveGroupButtonAction: IAction = {
    id: 3,
    label: 'Save Group',
    buttonType: 'button',
    command: this.updateUserGroup.bind(this),
    icon: 'pi pi-save',
    status$: this.formValid$,
    loading$: this.loadingState$,
  };
  readonly updateGroupPermission = AppPermissions.UpdateGroup;
  constructor(
    public viewModeService: ViewModeService,
    private entityTypeRequestService: EntityTypesDataService,
    private groupsDataService: GroupDataService,
    private toastService: ToastService,
    public mapperService: ResponsibilitiesMappingService
  ) {
    super(viewModeService, 'RESPONSIBILITY');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.formatGetChangedFormValues(this.getChangedFormValues().updateItems);
  }
  formatGetData() {
    let ret = { ...this.formGroup.getRawValue() };
    // let dFields = {};
    // ret.dynamics?.forEach(field=>{
    //     dFields[field.name] = field.value;
    // });
    // ret.dynamics = dFields;
    ret.type = ret.type.code;
    return ret;
  }
  setData(data: any) {
    this.selectedUsers = data?.members?.map((x) => {
      return { username: x };
    });
    if (this.orgSubscriber) this?.orgSubscriber?.unsubscribe();

    let patchVal = { ...data };
    if (data?.entityType) patchVal.type = { ...data.entityType };
    this.patchFormData(patchVal);
    this.data = data;

    if (this.itemId) {
      this.formGroup.get('type').disable();
    }
    !!!this?.data?.organization ? this.formGroup.get('parent').disable() : this.formGroup.get('parent').enable();

    this.orgSubscriber = this.formGroup?.controls?.organization?.valueChanges.subscribe((org) => {
      let parentControl = this.formGroup.get('parent');

      parentControl.setValue(null);

      !!!org ? parentControl.disable() : parentControl.enable();

      parentControl.updateValueAndValidity();
      this.formGroup.updateValueAndValidity();
    });
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      people: new FormControl(null),
      parent: new FormControl({ value: null, disabled: true }),
      description: new FormControl(null),
      groupName: new FormControl(null),
      owner: new FormControl(null),
      organization: new FormControl(null, Validators.required),
      // category: new FormControl(Entity.CategoryEnum.Responsibilities),
      dynamics: new FormGroup({}),
    });
  }

  formatGetChangedFormValues(updateItems: { key: string; value: any }[]) {
    let items = [];
    updateItems.forEach((element) => {
      if (element.key == 'type') {
        element.value = element?.value?.code;
      }
      items.push(element);
    });
    return items;
  }

  entityType: EntityTypeFieldDto = null;
  entityTypeLoading: boolean = false;
  onEntityTypeChange(value) {
    // if (!!!value) return;
    // this.entityTypeLoading = true;
    // this.entityTypeRequestService
    //     .getByIdOrCode(value?.code, { showLoading: false, showMsg: false })
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe({
    //         next: (res) => {
    //             this.entityType = res.data;
    //             this.entityTypeLoading = false;
    //         },
    //         error: (err) => {
    //             this.entityTypeLoading = false;
    //         },
    //     });
  }
  checkValueChanged(formCurrentData: any, key: string) {
    return this.data && (key == 'type' ? formCurrentData[key]?.code : formCurrentData[key]) != this.data[key];
  }
  setUsers(event) {
    // this.formGroup.patchValue({people:event.map(x=>x?.username)})
    this.currentUsers = [...event];
  }
  setInitialDataElements(event) {
    // this.formGroup.patchValue({people:event.map(x=>x?.username)})
    this.initialUsers = [...event];
  }
  updateUserGroup(redirectToPage = true) {
    let joinMembers: string[] = differenceBy(this.currentUsers, this.initialUsers, 'id').map((x) => x.id);
    let leaveMembers: string[] = differenceBy(this.initialUsers, this.currentUsers, 'id').map((x) => x.id);
    forkJoin({
      //    group: this.requestService.update({name:data.name}, (this.data as any).id),
      joinedMembers:
        joinMembers.length > 0 ? this.groupsDataService.joinGroup({ ids: joinMembers }, this.data.groupId) : of(null),
      removedMembers:
        leaveMembers.length > 0
          ? this.groupsDataService.leaveGroup({ ids: leaveMembers }, this.data.groupId)
          : of(null),
    }).subscribe({
      next: (res) => {
        this.toastService.success(MessageKeys.success, MessageKeys[`update${ModuleKeywords.Group}`]);
        // if (redirectToPage) {
        //     if (window?.history?.length > 1) {
        //         window?.history?.back();
        //     } else {
        //         this.requestService.navigateToListPage();
        //     }
        // }
      },
    });
  }
}
